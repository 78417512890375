import { defineStore } from 'pinia';
import { ref } from 'vue';

type cbFn = (isPrintMode: boolean) => void;

export const useSystemStore = defineStore('systemStore', () => {
  const printMode = ref(false);
  let cbfn: cbFn;

  function setPrintMode(val: boolean): void {
    printMode.value = val;

    if (val) {
      document.body.style.height = 'unset';
      document.body.style.overflow = 'auto';
      document.documentElement.style.height = 'unset';
      document.documentElement.style.overflow = 'auto';
    } else {
      document.body.style.height = '100%';
      document.body.style.overflow = 'hidden';
      document.documentElement.style.height = '100%';
      document.documentElement.style.overflow = 'hidden';
    }
  }

  function printModeChange(cb: (isPrintMode: boolean) => void) {
    cbfn = cb;
  }

  return {
    printMode,
    setPrintMode,
  };
});
