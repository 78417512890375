import { RouteRecordRaw } from 'vue-router';

export const routeList: Array<RouteRecordRaw> = [
  {
    path: '/template/create',
    name: 'create_template',
    component: () =>
      import(/* webpackChunkName temp-create */ '@/views/template/create.vue'),
    meta: {
      title: '图稿模板',
    },
  },
  {
    path: '/template/list',
    name: 'template_list',
    component: () => import('@/views/template/list.vue'),
    meta: {
      title: '模板管理列表',
    },
  },
  {
    path: '/template/price',
    name: 'template_price',
    component: () => import('@/views/template/price_list.vue'),
    meta: {
      title: '模板价格计划',
    },
  },
  {
    path: '/settings/authority',
    name: 'authority',
    component: () => import('@/views/settings/authority/index.vue'),
    meta: {
      title: '权限树维护',
    },
  },
  {
    path: '/settings/rules',
    name: 'rules',
    component: () => import('@/views/settings/rules/list.vue'),
    meta: {
      title: '系统角色管理',
    },
  },
  {
    path: '/tenant/list',
    name: 'tenantList',
    component: () => import('@/views/tenants/list.vue'),
    meta: {
      title: '公司列表',
    },
  },
  {
    path: '/tenant/detail',
    name: 'tenantDetail',
    component: () => import('@/views/tenants/detail.vue'),
    meta: {
      title: '公司详情',
    },
  },
  {
    path: '/user/list',
    name: 'userList',
    component: () => import('@/views/users/list.vue'),
    meta: {
      title: '帐户管理',
    },
  },
  {
    path: '/goods/goods-type',
    name: 'goodsTypeList',
    component: () => import('@/views/goods/goods-type.vue'),
    meta: {
      title: '商品分类管理',
    },
  },
  {
    path: '/goods/groups',
    name: 'goods_group_list',
    component: () => import('@/views/goods/goods-group.vue'),
    meta: {
      title: '商品信息分组',
    },
  },
  {
    path: '/goods/fields',
    name: 'goodsList',
    component: () => import('@/views/goods/goods-fields.vue'),
    meta: {
      title: '商品信息管理',
    },
  },
  {
    path: '/datas/list',
    name: 'dataList',
    component: () => import('@/views/datas/list.vue'),
    meta: {
      title: '图稿数据管理',
    },
  },
  {
    path: '/datas/enter',
    name: 'dataEnter',
    component: () => import('@/views/datas/enter.vue'),
    meta: {
      title: '商品数据录入',
    },
  },
  {
    path: '/washing/list',
    name: 'washingList',
    component: () => import('@/views/washing/washing-list.vue'),
    meta: {
      title: '洗涤标基础数据',
    },
  },
  {
    path: '/washing/groups',
    name: 'washingGroup',
    component: () => import('@/views/washing/group-list.vue'),
    meta: {
      title: '洗涤标分组',
    },
  },
  {
    path: '/color/list',
    name: 'colorList',
    component: () => import('@/views/colors/list.vue'),
    meta: {
      title: '颜色数据管理',
    },
  },
  {
    path: '/brand/list',
    name: 'brandList',
    component: () => import('@/views/brand/list.vue'),
    meta: {
      title: '品牌数据管理',
    },
  },
  {
    path: '/security/list',
    name: 'security_types',
    component: () => import('@/views/security/list.vue'),
    meta: {
      title: '安全技术类别管理',
    },
  },
  {
    path: '/security/types',
    name: 'securityTypes',
    component: () => import('@/views/security/types.vue'),
    meta: {
      title: '安全技术类别分类管理',
    },
  },
  {
    path: '/goods/codes',
    name: 'goods_codes',
    component: () => import('@/views/goods/goods-code.vue'),
    meta: {
      title: '公司69码管理',
    },
  },
  {
    path: '/standard/list',
    name: 'execute_standard',
    component: () => import('@/views/standard/list.vue'),
    meta: {
      title: '执行标准管理',
    },
  },
  {
    path: '/countries/list',
    name: 'country_list',
    component: () => import('@/views/countries/list.vue'),
    meta: {
      title: '国家信息管理',
    },
  },
  {
    path: '/producer/list',
    name: 'producerList',
    component: () => import('@/views/producer/list.vue'),
    meta: {
      title: '生产商信息管理',
    },
  },
  {
    path: '/size/list',
    name: 'sizeList',
    component: () => import('@/views/catetories/list.vue'),
    meta: {
      title: '尺码信息管理',
    },
  },
  {
    path: '/order/list',
    name: 'orderList',
    component: () => import('@/views/orders/list.vue'),
    meta: {
      title: '订单信息管理',
      keep: true,
    },
  },
  {
    path: '/order/style-num-orders',
    name: 'styleNumOrders',
    component: () => import('@/views/orders/style-num-list.vue'),
    meta: {
      title: '数据所属订单列表',
    },
  },
  {
    path: '/order/audit-list',
    name: 'OrderAuditList',
    component: () => import('@/views/orders/audit-list.vue'),
    meta: {
      title: '订单审核管理',
    },
  },
  {
    path: '/suppliers/produce',
    name: 'SuppliersList',
    component: () => import('@/views/suppliers/produce.vue'),
    meta: {
      title: '生产管理',
    },
  },
  {
    path: '/suppliers/delivery_list',
    name: 'DeliveryList',
    component: () => import('@/views/suppliers/delivery_list.vue'),
    meta: {
      title: '出货单管理',
      keep: true,
    },
  },
  {
    path: '/suppliers/delivery_detail',
    name: 'DeliveryDetail',
    component: () => import('@/views/suppliers/delivery_detail.vue'),
    meta: {
      title: '创建出货单',
    },
  },
  {
    path: '/suppliers/delivery_form',
    name: 'DeliveryForm',
    component: () => import('@/views/suppliers/delivery_form.vue'),
    meta: {
      title: '出货单明细',
    },
  },
  {
    path: '/suppliers/delivery_form',
    name: 'DeliveryForm',
    component: () => import('@/views/suppliers/delivery_form.vue'),
    meta: {
      title: '出货单明细',
    },
  },
  {
    path: '/stylenum/list',
    name: 'StyleNumList',
    component: () => import('@/views/stylenum/list.vue'),
    meta: {
      title: '款号管理',
    },
  },
  {
    path: '/loss/list',
    name: 'LossList',
    component: () => import('@/views/loss/list.vue'),
    meta: {
      title: '损耗管理',
    },
  },
  {
    path: '/product/name/list',
    name: 'ProductNameList',
    component: () => import('@/views/product/name_list.vue'),
    meta: {
      title: '品名管理',
    },
  },
  {
    path: '/dashboard/orders',
    name: 'DashBoardOrders',
    component: () => import('@/views/dashboard/orders.vue'),
    meta: {
      title: '订单报表',
    },
  },
  {
    path: '/history_order/sku_list',
    name: 'HistorySkuList',
    component: () => import('@/views/historyOrder/skus.vue'),
    meta: {
      title: '历史SKU列表',
    },
  },
  {
    path: '/history_order/list',
    name: 'HistoryOrderList',
    component: () => import('@/views/historyOrder/list.vue'),
    meta: {
      title: '历史物流补单列表',
    },
  },
  {
    path: '/history_order/detail',
    name: 'HistoryOrderDetail',
    component: () => import('@/views/historyOrder/order-detail.vue'),
    meta: {
      title: '历史物流补单详情',
    },
  },
  {
    path: '/skus',
    name: 'SkuBaseInfoList',
    component: () => import('@/views/skus/index.vue'),
    meta: {
      title: 'SKU基础信息',
    },
  },
  // 工具
  {
    path: '/tools/mock-api',
    name: 'MockApiTools',
    component: () => import('@/views/tools/mock.vue'),
    meta: {
      title: '数据模拟',
    },
  },
];
