// 所有枚举

/**
 * 展示在画布上所有组件的类型
 */
export enum ComponentType {
  Canvas = 'canvas',
  /**
   * 容器
   */
  Content = 'content',
  /**
   * 表格
   */
  Table = 'table',
  /**
   * 行
   */
  Row = 'row',
  /**
   * 文本
   */
  Text = 'text',
  /**
   * 动态字段
   */
  Field = 'field',
  /**
   * 行中的单元格
   */
  RowCell = 'row-cell',
  /**
   * 静态图片
   */
  Image = 'image',
  /**
   * 条形码
   */
  BarCode = 'bar-code',

  WashingHorizental = 'washing-h',

  WashingVertical = 'washing-v',

  QRCode = 'qr-code',

  RichText = 'rich-text',
}

export enum CommentGroup {
  Field = 1,
  Layout = 2,
  Static = 3,
}

export enum OrderTypeEnum {
  Normal = 1,
  Logistics = 2,
}
