import { Optional } from '../model/types';
import BaseClient from './BaseClient';
import type { ResponseData } from './BaseClient.type';
import type {
  SearchUserRequestDto,
  UserLoginRequest,
  SearchUserResponseDto,
  RegisteMyUserRequestDto,
  UserRulesRequest,
  UserAdminInfo,
  ResetPwdRequest,
  UpdateUserRequest,
} from './UserClient.type';

class _UserClient extends BaseClient {
  public login(data: UserLoginRequest): Promise<ResponseData<string>> {
    return this.post<ResponseData<string>>('/user/login', data);
  }

  public info(): Promise<ResponseData<UserAdminInfo>> {
    return this.get('/user/info');
  }

  public search(
    data: SearchUserRequestDto
  ): Promise<ResponseData<SearchUserResponseDto>> {
    return this.get('/user/search', data);
  }

  public registe(
    data: RegisteMyUserRequestDto
  ): Promise<ResponseData<boolean>> {
    return this.post('/user/registe', data);
  }

  public mapRules(data: UserRulesRequest): Promise<ResponseData<boolean>> {
    return this.post('/user/map-rules', data);
  }

  public resetPwd(
    data: Optional<ResetPwdRequest, 'dupPwd'>
  ): Promise<ResponseData<boolean>> {
    return this.post('/user/reset-pwd', data);
  }

  public update(data: UpdateUserRequest): Promise<ResponseData<boolean>> {
    return this.post('/user/update', data);
  }

  public getDomainCfg(
    domain: string
  ): Promise<ResponseData<Record<string, string>>> {
    return this.get('/tenant/domain', { domain });
  }
}

export const UserClient = new _UserClient();
