import { UserClient } from '@/service/client/UserClient';
import { UserAdminInfo } from '@/service/client/UserClient.type';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export interface TokenData {
  token: string;
}

export const useUserStore = defineStore('userStore', () => {
  const model = ref<UserAdminInfo>();

  async function getUserInfo() {
    const info = await UserClient.info();
    if (info.success && info.data) {
      model.value = info.data;
    }
  }

  return {
    model,
    getUserInfo,
  };
});
