// import type { Canceler } from 'axios';

// declare module 'axios' {
//   interface AxiosRequestConfig {
//     canceler?: Canceler;
//   }
// }

export interface ResponseData<T = any> {
  data: T;
  code: number;
  message: string;
  success: boolean;
}

export enum HttpMethod {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

export enum HttpCode {
  OK = 200,
  AuthExpire = 7777,
  UnknownError = 9999,
  InvalidParam = 9000,
  Forbidden = 9001,
}

export interface BaseEntity {
  id: number;
  create_at: string;
  create_by: string;
  status: number;
}

export interface RemoveByidModel<T = number> {
  id: T;
}

export interface BasePagination {
  count: number;
  page: number;
}
