import { createApp } from 'vue';
import App from './App.vue';
import { getRouter } from './router';
import { createPinia } from 'pinia';
import persistedstate from 'pinia-plugin-persistedstate';
import PageView from './components/PageView';

import './assets/scss/global.scss';
import './assets/fonts/index.scss';
import { initPermission } from './common/utils/permission';

const app = createApp(App);
const pinia = createPinia();
pinia.use(persistedstate);
app.use(pinia);

app.component('page-view', PageView);

initPermission().then(() => {
  const router = getRouter();
  app.use(router);
  app.mount('#app');
});
