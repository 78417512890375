import { defineStore } from 'pinia';
import { TokenData } from './user';

export const useTokenStore = defineStore('token', {
  persist: true,
  state(): TokenData {
    return {
      token: '',
    };
  },
  actions: {
    saveToken(token: string): void {
      this.token = token;
    },
    delToken(): void {
      this.token = '';
    },
  },
});
