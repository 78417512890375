export interface EnvInfoData {
  baseUrl: string;
  authWhiteList: string[];
  loginPage: string;
  env: 'dev' | 'prod';
}

const env: Record<'development' | 'production', EnvInfoData> = {
  development: {
    baseUrl: 'http://localhost:3000/',
    authWhiteList: ['/user/login', '/user/logout', '/tenant/domain'],
    loginPage: '/login',
    env: 'dev',
  },
  production: {
    baseUrl: 'https://www.bpaos.com/',
    authWhiteList: ['/user/login', '/user/logout', '/tenant/domain'],
    loginPage: '/login',
    env: 'prod',
  },
};

export const envInfo = () => {
  return env[process.env.NODE_ENV as 'development' | 'production'];
};
