import { BasicTenantEntity } from '../model/types';
import { BasePagination } from './BaseClient.type';

export interface DeliveryEntity extends BasicTenantEntity {
  delivery_id: string;
  status: number;
  orders: string;
  delivery_date: string;
  logistics_company: string;
  logistics_code: string;
  pkg_num: number;
  pkg_img: string;
  consignee_order: string;
}

export interface DeliveryDetailData extends DeliveryEntity {
  consignee_company: string;
  consignee: string;
  consignee_phone: string;
  delivery_address: string;
}

export type DeliveryFormData = Partial<DeliveryEntity> & {
  consignee_company?: string;
  consignee?: string;
  delivery_address?: string;
  consignee_phone?: string;
};

export interface DeliverySearchParams extends BasePagination {
  delivery_id?: string;
  start_date?: string;
  end_date?: string;
  order_id?: string;
}

export enum DeliveryStatusEnum {
  /**
   * 新创建
   */
  New = 1,
  /**
   * 已确认
   */
  Confirmed = 2,
  /**
   * 已作废
   */
  Removed = 3,
}

export interface DeliveryRecordData {
  brand_company: string;
  delivery_date: string;
  confirm_date: string;
  delivery_id: string;
  logistics_code: string;
  logistics_company: string;
  orders: string;
  service_company: string;
  status: DeliveryStatusEnum;
  total: string;
}

export interface DeliveryFormDetail {
  delivery_id: string;
  servicer_name: string;
  servicer_address: string;
  servicer_phone: string;
  servicer_fax: string;
  confirm_date: string;
  logistics_company: string;
  logistics_code: string;
  pkg_num: string;
  consignee_company: string;
  delivery_address: string;
  consignee_phone: string;
  consignee: string;
  brand_name: string;
  orders: string;
  sub_orders?: DeliveryFromOrderData[];
}

export interface DeliveryFromOrderData {
  order_id: string;
  sub_order_id: string;
  color_id: number;
  num: number;
  color_code: string;
  color_name_cn: string;
  color_name_en: string;
  size_name: string;
  template_name: string;
  style_num: string;
  chn_height: string;
}
