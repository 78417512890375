import { createRouter, createWebHistory } from 'vue-router';
import Layout from '@/components/system/Layout.vue';
import NotFoundComponent from '@/views/404.vue';
import { useUserStore } from '@/pinia/user';
import { routeList } from './routes';

const generateRoutes = () => {
  const userStore = useUserStore();
  const pages = (userStore.model?.resources || []).filter(
    (d) => d.type === 'Page'
  );
  // console.log(pages, 'auths');
  return routeList.filter((d) => {
    return pages.find((p) => p.routerName === d.name);
  });
};

export const getRouter = () => {
  const routes = generateRoutes();
  return createRouter({
    history: createWebHistory('platform'),
    routes: [
      {
        path: '/',
        name: 'home',
        component: Layout,
        children: [
          {
            name: 'home_page',
            path: '/',
            component: () => import('@/views/index.vue'),
            meta: {
              title: '欢迎使用',
            },
          },
          ...(routes || []),
        ],
      },
      {
        path: '/print',
        name: 'print',
        component: () => import('@/views/suppliers/print.vue'),
      },
      {
        path: '/history-print',
        name: 'history_print',
        component: () => import('@/views/historyOrder/print.vue'),
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/users/login.vue'),
        meta: {
          noLogin: true,
        },
      },
      {
        path: '/:catchAll(.*)',
        component: NotFoundComponent, // 这是您的404组件
      },
    ],
  });
};
